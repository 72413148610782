import React from 'react';
import mapboxgl from "mapbox-gl";


class Map extends React.Component {
  componentDidMount() 
  {
    const { mallData } =  this.props; 

    if(typeof(window) !== "undefined" && 
        typeof(mapboxgl) !== "undefined") {
      
      mapboxgl.accessToken = 'pk.eyJ1IjoibWVyZ2VjaGljYWdvIiwiYSI6ImNrMHljMThsdDBlYW4zY29ib2JhYXhlZWYifQ.uem2bbxL5oqjRsx9FoqIgQ';

      window.markers = [];
      window.map = new mapboxgl.Map({
        container: 'map-container',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-87.6244, 41.8756],
        hash: true,
        zoom: 4
      });
  
      window.map.on('load', function(e) {
        // Add the data to your map as a layer
        window.map.addSource('places', {
          type: 'geojson',
          data: mallData
        });
      });
  
      mallData['features'].forEach(function(marker,index) {
        var el = document.createElement('div');
        el.className = 'marker';
        el.dataset.title = marker.properties.title;
        el.addEventListener('click', function(event) {
          window.markerClick(event,marker)
        })
  
        window.markers[marker.properties.id] = new mapboxgl.Marker(el, { offset: [0, -23] })
          .setLngLat(marker.geometry.coordinates)
          .addTo(window.map);
      });
  
      window.markerClick = function(e,marker) {
        window.findMatchInList(e,marker.properties.title)
      }
  
      window.findMatchInList = function(markerClicked, searchTerm){
        let resultEl =document.querySelectorAll("#railL .result");
        let resultTxt =document.querySelectorAll("#railL .result h3");
        let resultMarker =document.querySelectorAll("#map-container .marker");
      
        for (let el of resultEl) {el.classList.remove('active')}        //remove active from text list
        for (let el of resultMarker) {el.classList.remove('active')}    //remove active from map
      
        for (let el of resultTxt) 
          {
            if (el.textContent.includes(searchTerm)) {
              el.parentElement.parentElement.classList.add('active');   //mark current in text list
              el.scrollIntoView();
              break
            }
          }
          markerClicked.target.classList.add('active')                  //mark current in map
      }
    } 
  }

  render() {
    return (
      <div id="map-container">
      </div>
    );
  }
}

export default Map;
