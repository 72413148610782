import PropTypes from "prop-types"
import React from "react"
import axios from 'axios';
import { Helmet } from "react-helmet"

export default class EmailSignUp extends React.Component {
    componentDidMount() {
        if(typeof(window) !== "undefined" && 
        typeof(document) !== "undefined") {
 
            const emailBtn = document.getElementById('formEmailBtn');

            function validateEmail(email) {
                var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            }

            function validate() {
                var $result = document.getElementById("result");
                var email = document.querySelector("#email-right #email").value.replace('+', '%2B');
                var emailleftsignup = document.getElementById("email-left-signup");
                var emailleftthanks = document.getElementById("email-left-thanks");
                var emailright = document.getElementById("email-right");

                $result.innerHTML = "";
              
                if (validateEmail(email)) {

                    document.getElementById("custom-225218").value = email;
                    document.querySelector("#vboutEmbedFormWrapper-26564 input[type='submit']").click();

                    emailleftsignup.className += emailleftsignup.className + ' hide';
                    emailleftthanks.className = emailleftthanks.className.replace('hide', '');
                    emailright.className += emailright.className + ' hide';

                    //const axiosConfig = {
                    //  headers: {
                    //    'Content-Type': 'application/json;charset=UTF-8',
                    //  }
                    //}
                    //const url = 'https://marketo.whereissanta.com/';
                    //axios.post(url, { email }, axiosConfig)
                    //  .then(response => {
                    //    const hash = response.data;
                    //    if (window.Munchkin) {
                    //      window.Munchkin.munchkinFunction('associateLead', {
                    //        'Email': encodeURI(email),
                    //        'LeadSource': 'Email Signup'
                    //      }, hash);
                    //      emailleftsignup.className += emailleftsignup.className + ' hide';
                    //      emailleftthanks.className = emailleftthanks.className.replace('hide', '');
                    //      emailright.className += emailright.className + ' hide';
                    //    }
                    //  }).catch(err => {
                    //      console.log(err)
                    //      emailleftsignup.className = emailleftsignup.className.replace('hide', '');
                    //      emailleftthanks.className += emailleftthanks.className  + ' hide';
                    //      emailright.className = emailright.className.replace('hide', '');
                    //    })


                } else {
                  $result.innerHTML = email + " is not valid. Please re-enter and click again.";
                }
                return false;
              }
              
              emailBtn.addEventListener('click', (event) => {
                
                validate();
                event.preventDefault();
              })

        }


    }

    render() {
        return (
            <>
                <Helmet>
                    <script async src="https://www.vbt.io/ext/vbtforms.js?lang=en" charset="utf-8"></script>
                </Helmet>
                <div className={"full-width " + this.props.size} id="EmailSignUp">
                <div className="col2 left">
                    <div id="email-left-signup" className="">
                    Sign up to get exclusive deals and<br />
                    freebies from our partners.<br />
                    The magic lasts all year.
                    </div>
                    <div id="email-left-thanks" className="hide">
                    Thank you for signing up! Seasonal<br />
                    deals will start appearing in your inbox<br />
                    soon.
                    </div>                
                </div>
                <div id="email-right" className="col2 right">
                    <div className="label">Your email address</div>
                    <div id="" className="form">
                        <input type="text" defaultValue="" id="email" className="copyInput" />
                        <a href="#" id="formEmailBtn" className="formSearchBtn" aria-label="Search"> </a>
                    </div>
                    <div id="result" className="validation"></div>
                    </div>

                <div id="vboutEmbedFormWrapper-26564">
                    <form action="https://www.vbt.io/embedcode/submit/26564/?_format=page" target="_blank" method="post" data-vboutform="26564" id="vboutEmbedForm-26564" name="vboutEmbedForm-26564" enctype="multipart/form-data">
                        <h1>Where is Santa 1 1 Form</h1>

                        <div id="vboutEmbedFormResponse-26564" ></div>
                        <fieldset>
                            <div class="vboutEmbedFormRow">
                                <label class="title" for="custom-225218">Email<span class="required-asterisk">*</span></label>
                                <div class="vboutEmbedFormField"><input type="email" name="vbout_EmbedForm[field][225218]" id="custom-225218" value="" class="vfb-text  required  validate-email " /></div>
                            </div>


                            <div class="vboutEmbedFormRow"><input type="submit" value="CONTACT US!" /></div>
		                    </fieldset>
	                    </form>
                    </div>
                    </div>
            </>
        );
    }
}


